
import {Options, Vue} from 'vue-class-component';
import moment from "moment";
@Options({
  props: {
    time: {
      type: [String, String],
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      nian:0
    }
  },
  computed: {

  },
  watch:{
    "time":{
      handler(newVal){
        console.log(this.time)
        let time: any = '';
        if(!newVal){
          this.nian = 0
          return
        }

        if (/^\d+\d$/.test(newVal)) {
          time =newVal
        } else {
          time = moment(newVal).unix()
        }
        //获取当前时间
        let m1 = moment(time*1000);
      //获取需要对比的时间
        let m2 = moment();
        let yue = m2.diff(m1, 'month')

        this.nian = Math.round(yue/12)

      },
      immediate:true

    }
  }

})

export default class ji_suan_nian extends Vue {

}
