import http from "@/api/http";
export default {
    name:'public_shen_qing',
    data(){
        return {
            url:'',
            form:{}
        }
    },
    methods: {
        getForm(){
            return this.form
        },
        onSubmit() {
            http.post(this.url, this.form).then(da => {
                if (da == '') {
                    this.$emit('lol_change', 1)
                }
            })
        }
    }
}
