
import {Options, Vue} from 'vue-class-component';
import yanZhengMa from "@/components/btn/yanZhengMa.vue";
import http from "@/api/http";
import public_shen_qing from "@/views/gcs/public/public_shen_qing";

@Options({
  components: {yanZhengMa},
  mixins: [public_shen_qing],
  data() {
    return {
      url: "/portal/gcs/validate_ge_ren_xin_xi",
      form: {}
    }
  },
  mounted() {
    console.log(this.getForm())
  },
  methods: {}
})

export default class ge_ren_info extends Vue {

}
