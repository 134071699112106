<template>
  <div class="nei-cong bg-fff">
    <div class="zhu-tu">
      <van-image width="100%" :src="image" v-if="image"/>
    </div>
    <van-row v-if="!status" class="bu-zhou-nei-content">
      <van-col span="8" v-for="(vo,index) in buZhouList" :key="index">
        <div class="text-center text-aaa" :class="{'text-zs':index <= buZhou }">
          <i class="iconfont bu-zhou-icon" :class="vo.image"></i>
          <h5 class="bu-zhou-name">{{ vo.name }}</h5>
        </div>
      </van-col>
    </van-row>
    <div class="form">
      <template v-if="status">
        <div class="text-center tu-biao">
          <van-icon name="wechat-pay"/>
        </div>
        <h4 class="ti-shi-yu text-center">您的申请已经提交，我们将在1个工作日内联系您，以便完成相关审核</h4>
        <h4 class="text-center dian-hua">
          <a :href="'tel:'+tel">
            服务热线：{{ tel }}
          </a>
        </h4>
        <div class="text-center close-web">

          <van-button type="primary" url="https://work.weixin.qq.com/kfid/kfc4de4ac4074baabef">在线客服</van-button>
        </div>
      </template>
      <template v-else>
        <ge_ren_info ref="ge_ren_info" @lol_change="change" v-show="buZhou===0"/>
        <shan_chang_ji_neng ref="shan_chang_ji_neng" @lol_change="change" v-show="buZhou===1"/>
        <xiang_guan_jing_yan ref="xiang_guan_jing_yan" @lol_change="change" @lol_submit="lol_submit"
                             v-show="buZhou===2"/>
      </template>
    </div>
  </div>
</template>

<script>
import http from "@/api/http";
import ge_ren_info from "@/views/gcs/public/ge_ren_info.vue";
import shan_chang_ji_neng from "@/views/gcs/public/shan_chang_ji_neng.vue";
import xiang_guan_jing_yan from "@/views/gcs/public/xiang_guan_jing_yan.vue";
import publicApi from "@/api/dataList/publicApi"
import chaJian from "@/api/chaJian";
import {isLogin} from "@/api/cookie";
import {setFws} from "@/api/dataList/userApi";
import _ from 'lodash'

export default {
  name: 'index',
  components: {ge_ren_info, shan_chang_ji_neng, xiang_guan_jing_yan},
  data() {
    return {
      tel: '',
      status: false,
      image: "",
      buZhou: 0,
      buZhouList: [
        {
          name: '1 个人信息',
          image: 'icongerenxinxishezhi'
        },
        {
          name: '2 擅长技能',
          image: 'iconshu'
        },
        {
          name: '3 相关经验',
          image: 'iconxunjianjingyanku'
        }
      ]
    }
  },
  mounted() {
    http.get("/portal/index/get_gong_cheng_shi_image").then(da => {
      if (da.code == 1) {
        this.image = da.data.content
      }
    })
    publicApi.getKeHuTel().then(da => {
      this.tel = da
    })

  },
  methods: {
    change(number) {
      this.buZhou += number
    },
    async lol_submit() {
      let form = {
        ...this.$refs.ge_ren_info.getForm(),
        ...this.$refs.shan_chang_ji_neng.getForm(),
        ...this.$refs.xiang_guan_jing_yan.getForm()
      }
      let da = await http.post("/portal/gcs/add_shen_qing", form)
      if (_.get(da, 'code') == 1) {
        this.status = true
        if (isLogin()) {
          setFws()
        }
      }
    },
    closeWeb() {
      chaJian.closeWeb()
    }
  }
}

</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var.less";

.zhu-tu {
  border-bottom: .5rem solid @gray-2;
  background-color: @gray-2;
  min-height: 200px;
}

.nei-cong {
  min-height: 100%;
}

.bu-zhou-icon {
  font-size: 4rem;
}

.bu-zhou-nei-content {
  margin-top: 1rem;
}

.bu-zhou-name {
  margin-top: 0;
}

.form {
  margin-top: 2rem;
}

.tu-biao {
  .van-icon {
    font-size: 10rem;
    color: @blue;
  }
}

.ti-shi-yu {
  padding: 2rem;
  line-height: 3rem;
}

.close-web {
  margin-top: 3rem;
}
</style>
