
import {Options, Vue} from 'vue-class-component';
import checkbox_btn from "@/components/btn/checkbox_btn.vue";
import publicApi from "@/api/dataList/publicApi"
import Time from "@/components/time/Time.vue";
import DuoXuanAddress from "@/components/select/DuoXuanAddress.vue";
import ji_suan_nian from "@/components/time/ji_suan_nian.vue";
import http from "@/api/http";
import public_shen_qing from "@/views/gcs/public/public_shen_qing";
import cengJiuZhiGongSi from './cengJiuZhiGongSi.vue'
import moment from "moment";
import Address from "@/components/select/Address.vue";

@Options({
  components: {Address, cengJiuZhiGongSi, ji_suan_nian, checkbox_btn, input_time: Time, DuoXuanAddress},
  mixins: [public_shen_qing],
  data() {
    return {
      url: "/portal/gcs/validate_shan_chang_ji_neng",
      form: {
        gong_si: {},
        jin_zhi_number: []
      },
      ji_neng_list: []
    }
  },
  mounted() {
    publicApi.getShangChangJiNeng().then(da => {
      this.ji_neng_list = da
    })
    this.$refs.jiuZhi.add()
    // this.add()
  },
  methods: {}
})

export default class shan_chang_ji_neng extends Vue {

}
