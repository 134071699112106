<template>
  <div class="container">
    <van-form @submit="onSubmit">
      <h5 class="ti-shi">
        <van-row>
          <van-col span="12">
           技术经验
          </van-col>

          <van-col span="12" class="text-right">
            <a href="javascript:;" @click="ji_shu_an_li = !ji_shu_an_li">展示范例 <i class="iconfont iconxiala"></i></a>
          </van-col>
        </van-row>
      </h5>
      <div class="an-li" v-if="ji_shu_an_li" v-html="langVal.content"></div>
      <van-cell-group inset>
        <van-field
            :show-word-limit="true"
            v-model="form.ji_shu_jing_yan"
            rows="2"
            autosize
            maxlength="1000"
            type="textarea"
            placeholder="请详细填写您的技术经验和能力情况，展示您的实力，方便接单。"
            show-word-limit
        />
      </van-cell-group>
      <h5 class="ti-shi">
        <van-row>
          <van-col span="12">
            项目经验
          </van-col>
          <van-col span="12" class="text-right">
            <a href="javascript:;" @click="xiang_mu_an_li = !xiang_mu_an_li">展示范例 <i class="iconfont iconxiala"></i></a>
          </van-col>
        </van-row>
      </h5>
      <div class="an-li" v-if="xiang_mu_an_li" v-html="langVal.else_content"></div>
      <van-cell-group inset>
        <van-field
            v-model="form.xiang_mu_jing_yan"
            rows="2"
            autosize
            maxlength="1000"
            type="textarea"
            placeholder="请详细填写您参与过的项目，以及您在项目中所做的具体工作。"
            show-word-limit
        />
      </van-cell-group>

      <div class="container">
        <div class="ye-du-xie-yi" style="margin:20px 0">
          <van-checkbox :disabled="is_page" v-model="tongYi" icon-size="15px" shape="square">
            我已阅读并同意 <a href="javascript:" @click.stop="showPage = true">《云佐平台入驻协议》</a>
          </van-checkbox>
        </div>
      </div>

      <div style="margin: 16px;">
        <van-row gutter="10">
          <van-col span="10">
            <van-button block @click="$emit('lol_change',-1)">上一步</van-button>
          </van-col>
          <van-col span="14">
            <van-button block type="primary" native-type="submit">提交申请</van-button>
          </van-col>
        </van-row>
        <div style="margin-top: 30px">
          <h5 class="text-center">
            <a :href="`tel:${telInfo.content}`">联系商务部客服</a>
          </h5>
          <h5 class="text-center">
            客服电话 <a :href="`tel:${keFu}`">{{ keFu }}</a>
          </h5>
        </div>
      </div>

    </van-form>


    <van-popup
        v-model:show="showPage"
        position="bottom"
        round
        :style="{ height: '95%' }"
    >
      <yueDuXieYi v-model:show="showPage" v-if="showPage"/>
    </van-popup>

  </div>
</template>

<script>
import {Options, Vue} from 'vue-class-component';
import http from "@/api/http";
import basis from "@/components/class/basis";
import public_shen_qing from "@/views/gcs/public/public_shen_qing";
import yueDuXieYi from "@/views/open/yueDuXieYi.vue";
import din_shi from "@/api/din_shi";

@Options({
  components: {yueDuXieYi},
  mixins: [public_shen_qing],
  data() {
    return {
      showPage: false,
      tongYi: false,
      ji_shu_an_li: false,
      xiang_mu_an_li: false,
      langVal: {},
      form: {},
      keFu: '',
      telInfo: {},
    }
  },
  mounted() {
    http.get("/portal/index/get_lang_val", {
      params: {name: "技术项目案例"}
    }).then(da => {
      if (da.code == 1) this.langVal = da.data
    })

    http.get("/portal/index/get_ke_hu_tel").then(da => {
      if (da.code == 1) {
        this.keFu = da.data.content
      }
    })

    http.get("/portal/index/getShangWuBuTel").then(da => {
      if (da.code === 1) {
        this.telInfo = da.data
      }
    })
  },
  methods: {
    onSubmit() {
      if (!this.tongYi) {
        din_shi.error('请同意云佐平台入驻协')
        return;
      }
      this.$emit('lol_submit')
      // http.post("/portal/gcs/validate_xiang_guan_jing_yan", this.form).then(da => {
      //   if (da == '') {
      //     this.$emit('lol_submit')
      //   }
      // })

    }
  }
})

export default class xiang_guan_jing_yan extends Vue {

}
</script>
<style scoped lang="less">
@import "../../../assets/basis_css/style/var.less";

.ti-shi {
  font-weight: normal;
  color: @gray-8;
  padding-left: 1rem;
  padding-right: 1rem;

  .iconfont {
    font-size: 12px;
  }
}

.an-li {
  background-color: @gray-2;
  padding: @padding-sm;
  border-radius: @border-radius-md;
}

.ye-du-xie-yi {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.ye-du-xie-yi-fu-dong {

  //position: absolute;
  //top: 0;
  //left: 0;
  //right: 0;
  //margin: auto;
  //width: 270px;
}
</style>
