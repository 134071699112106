<template>
  <van-field
      :required="required"
      v-model="fieldValue"
      is-link
      readonly
      :label="title"
      :placeholder="placeholder"
      @click="show_page"
  />
  <van-popup v-model:show="show" teleport="body" round position="bottom">
    <van-cascader
        v-model="cascaderValue"
        title="请选择"
        :field-names="{text:'name',value:'id',coe:'id'}"
        :options="options"
        @close="show = false"
        @finish="onFinish"
    />
  </van-popup>
</template>

<script>
import axios from "@/api/http.js";
import XEUtils from "xe-utils";
//选择地址
/**
 <Address
 title="案例地区"
 v-model:id="form.address_id"
 v-model:name="form.address_name"
 />
 */
export default {
  name: 'Address',
  props: {
    id: [String, Number],
    name: [String, Number],
    //是否只读
    readonly: {
      type: Boolean,
      default() {
        return false
      }
    },
    //默认值 id
    val: {
      type: String,
      default() {
        return ''
      }
    },
    //提示
    placeholder: {
      type: String,
      default() {
        return '请选择'
      }
    },
    //标题
    title: {
      type: String,
      default() {
        return '地区'
      }
    },
    //配置获取地址，是否简洁地址 类型有：jian_jie、quan_bu、all
    dataType: {
      type: String,
      default() {
        return 'di_zhi'
      }
    },
    required: {
      type: Boolean,
      default: false
    }

  },
  data() {
    return {
      show: false,
      fieldValue: this.val,
      fieldId: [],
      cascaderValue: '',
      options: [],
      level: 0,
      form_list: [],
      dataTypeList: {
        "di_zhi": {//地址 显示部分地区
          url: '/portal/index/get_address',
        },
        fu_wu_di_qu: {//服务地区 显示部分地区 二级
          url: '/portal/index/get_di_qu',
        },

        "all": { //显示全部地址 三级
          url: '/portal/index/get_address_all',
        }

      }
    }
  },
  mounted() {
    this.getData(0, da => {
      this.options = da
      this.jianTing(this.id)
      this.$watch('id', this.jianTing)
    })
  },
  methods: {
    jianTing(val) {
      if (XEUtils.isUndefined(val)) return
      let list = [];
      let ids = XEUtils.toString(val).split(',').map(n => parseInt(n))
      ids.forEach(n => {
        XEUtils.eachTree(this.options, n2 => {
          if (n2.id == n) {
            list.push(n2)
          }
        })
      })
      this.onFinish({
        selectedOptions: list
      })
    },
    show_page() {
      if (!this.readonly) {
        this.show = true
      }

    },
    getData(id, fn) {
      axios.get(this.dataTypeList[this.dataType].url, {
        params: {id: id}
      }).then(da => {
        fn(da.data)
      })
    },
    onFinish({selectedOptions}) {
      this.show = false;
      this.form = selectedOptions.map(n => {
        return {
          id: n.id,
          name: n.name,
          parent_id: n.parent_id
        }
      })
      this.$emit("update:form", this.form_list)
      this.fieldValue = selectedOptions.map((option) => option.name).join('/');
      this.fieldId = selectedOptions.map((option) => option.id);
      this.$emit("update:id", this.fieldId.join(','))//id字符串
      this.$emit("update:name", this.fieldValue)//名称自负床
      //id、名称 数字
      this.$emit("dataEdit", {
        fieldValue: this.fieldValue.split('/'),
        fieldId: this.fieldId
      })
    },
    qingLong() {
      this.fieldValue = ''
      this.$emit("update:form", [])
      this.$emit("update:id", '')//id字符串
      this.$emit("update:name", '')//名称自负床
    }
  },
  watch: {
    name(val) {
      if (!XEUtils.isUndefined(this.id)) return
      this.fieldValue = val
    }
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
