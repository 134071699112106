<template>
  <div v-for="(vo,index) in jin_zhi_number">
    <van-cell-group inset>
      <van-field
          v-model="vo.gong_si_name"
          placeholder="曾就职的最知名的公司"
      />
      <van-field
          v-model="vo.zhi_wei"
          placeholder="如：中级工程师、高级工程师"
      />
    </van-cell-group>
    <h5 class="text-aaa ti-shi">
      <template v-if="index+1 == jin_zhi_number.length && jin_zhi_number.length <= 2">
        <a href="javascript:;" @click="add">
          <i class="iconfont iconxuanfutianjia"></i>
          添加
        </a>
        &nbsp;&nbsp;&nbsp;
      </template>
      <a href="javascript:;" @click="del(index)" v-if="jin_zhi_number.length >1">
        <i class="iconfont iconquxiao"></i>
        删除
      </a>
    </h5>
  </div>
</template>

<script>
import XEUtils from "xe-utils";
export default {
  name: 'cengJiuZhiGongSi',
  mixins: [],
  props: {
    list: Array
  },
  data() {
    return {
      jin_zhi_number: []
    }
  },
  mounted() {
  },
  methods: {
    add() {
      this.jin_zhi_number.push({
        id: new Date().getTime(),
        gong_si_name: '',
        zhi_wei: ''
      })
    },
    del(index) {
      this.jin_zhi_number.splice(index, 1)
    },
  },
  watch: {
    jin_zhi_number: {
      handler(newVal) {
        this.$emit('update:list',newVal)
      },
      // 深度监听 属性的变化
      deep: true,
      // 立即处理 进入页面就触发
      immediate: true,
    },
    list: {
      handler(newVal) {
        if (!XEUtils.isArray(newVal)) return
        this.jin_zhi_number = newVal
      },
      // 深度监听 属性的变化
      // 立即处理 进入页面就触发
      immediate: true,
    }
  },
  computed: {}
}
</script>

<style scoped>
.ti-shi{
  padding-left: 10px;
  padding-bottom: 10px;
  text-align: right;
  padding-right: 10px;
}
</style>
