<template>
  <div class="list-btn">
    <span class="an-niu"
          @click="change(index)"
          :class="{'an-niu-xuan-zhong':cheId.includes(vo.id)}"
          v-for="(vo,index) in selfList"
    >
    {{ vo.name }}
  </span>
    <span class="an-niu an-niu-xuan-zhong"
          v-for="(vo,index) in ziDingList"
          :key="index"
          @click="delVal(vo)"
    >
    {{ vo }}
  </span>
    <span
        v-if="custom"
        class="an-niu"
        @click="addZiDing"
    >
      其他擅长领域
    </span>
    <van-action-sheet v-model:show="pageStatus" title="请输入其他擅长领域">
      <div class="zi-ding">
        <div class="van-hairline--bottom">
          <van-field
              ref="ziDinVal"
              v-model.trim="ziDinVal"
              type="textarea"
              placeholder="请输入其他擅长领域"
          />
        </div>
        <div class="fen-ge"></div>
        <van-button type="primary" block @click="yes">确定</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import {Options, Vue} from 'vue-class-component';
import * as constants from "constants";
import din_shi from "@/api/din_shi.js"
import XEUtils from "xe-utils";
import AlertButton from "@/views/open/alertButton";
import {Dialog} from 'vant';
export default {
  name: 'checkbox_btn',
  components: {AlertButton},
  props: {
    custom: Boolean,//是否现实自定义按钮
    customList:String,//自定义创建的内容
    id: [String, Number],
    maxLength: {
      type: Number,
      default() {
        return 0
      }
    },
    list: {
      type: Array,
      default() {
        return [
          {name: '电影', id: 'dian_ying'}
        ]
      }
    },
  },
  data() {
    return {
      ziDinVal: "",
      pageStatus: false,
      selfList: [],
      cheId: [],
      ziDingList: [],
    }
  },
  methods: {
    delVal(val) {
      Dialog.confirm({
        message: '确定要删除：' + val
      }).then(da=>{
        XEUtils.remove(this.ziDingList,n=>n===val)
        this.changeZiDing()
      })
    },
    yes() {
      if (this.ziDinVal === '') {
        din_shi.error('不能为空')
      }
      this.ziDingList.push(this.ziDinVal)
      this.pageStatus = false
      this.ziDinVal = ''
      this.changeZiDing()
    },
    changeZiDing(){
      this.$emit('update:customList',this.ziDingList.join(','))
    },
    addZiDing() {
      this.pageStatus = true
      this.$nextTick(() => {
        this.$refs.ziDinVal.focus()
      })
    },
    change(index) {
      if (this.cheId.includes(this.list[index].id)) {
        XEUtils.remove(this.cheId, n => n == this.list[index].id)
      } else {
        //防止选择太多
        if (this.cheId.length > this.maxLength && this.maxLength > 0) {
          din_shi.error(`最多选择${this.maxLength}个`)
          return
        }

        this.cheId.push(XEUtils.toNumber(this.list[index].id))
      }

      let data = this.selfList.filter(n => this.cheId.includes(n.id))

      this.$emit('update:value', data)
      this.$emit('update:name', data.map(n => n.name).join(','))
      this.$emit('update:id', data.map(n => n.id).join(','))
    }
  },
  watch: {
    "list": {
      handler(newName) {
        this.selfList = newName.map(n => {
          if (typeof n.checkbox_status == 'undefined') {
            n.checkbox_status = false
          }
          return n
        })
      },
      immediate: true
    },
    id: {
      handler(newName) {
        if (XEUtils.isUndefined(newName)) return
        this.cheId = XEUtils.toString(newName).split(',').map(n => XEUtils.toNumber(n));
      },
      immediate: true
    }

  }
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var.less";

.an-niu {
  border: 1px solid @gray-6;
  padding: .2rem 1rem;
  margin: .5rem .5rem;
  display: block;
  float: left;
  border-radius: @border-radius-md;
  color: @gray-6;
}

.list-btn {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.an-niu-xuan-zhong {
  color: @white;
  border-color: @blue;
  background-color: @blue;
}

.zi-ding {
  //padding-bottom: 20px;
  .fen-ge {
    height: 20px;
    background-color: @gray-3;
  }

}
</style>
